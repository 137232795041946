import { colors, createTheme, ThemeProvider } from '@mui/material'
import dynamic from 'next/dynamic'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useLogger } from '../common/Logger'

const Home: React.FC = () => {
    const log = useLogger()
    const Home = dynamic(() => import('../components/Home'), { ssr: false })

    useEffect(() => {
        log.info('トップ画面表示')
    }, [])

    const theme = createTheme({
        palette: {
            primary: {
                main: colors.common.white,
            },
            mode: 'dark',
        },
    })

    return (
        <ThemeProvider theme={theme}>
            <StyledContainer>
                <Home />
            </StyledContainer>
        </ThemeProvider>
    )
}

const StyledContainer = styled.div`
    width: 100%;
    height: 100vh;
    background: lightgray;
`

export default Home
